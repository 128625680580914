import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "../../components";
import { useSelector } from "react-redux";
import { selectEmployeeProfile } from "../../utils/redux/reducers/auth";
import { updateProfile } from "../../utils/apis";
import { ROUTE_PATH } from "../../assets/constants";
import { ThreeDot } from "react-loading-indicators";
import { useAuth } from "../../contexts/authContext";
import { delay } from "../../utils";
import IsolationTop from "../../assets/images/Isolation_mode_top.png";
import EmptyProfile from "../../assets/images/emtpy-profile.png";
import IconProfile from "../../assets/images/icon-profile.png";
import IsolationBottom from "../../assets/images/Isolation_mode_bottom.png";

const Profile = () => {
  const navigate = useNavigate();
  const employeeProfile = useSelector(selectEmployeeProfile);
  const { logout } = useAuth();
  const [isLoading, SetIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  //   const [base64Img, setBase64IMG] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    handleGetProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProfile = async () => {
    if (employeeProfile !== null) {
      setImageUrl(employeeProfile.photos[0].url);
      setFirstName(employeeProfile.names[0].givenName);
      setLastName(employeeProfile.names[0].familyName);
    } else {
      logout();
    }
  };
  const handleUpdateProfile = async () => {
    try {
      // can't success request permission edit profile names
      //   const data = {
      //     names: [{ givenName: firstName, familyName: lastName }],
      //   };
      //   const res = await updateProfile(data, "name");
      //   console.log("res update profile", res);
      SetIsLoading(true);
      localStorage.setItem("is_first_time", true);
      await delay(3000);
      SetIsLoading(false);
      navigate(ROUTE_PATH.HOME);
    } catch (error) {
      console.log("err login google", error);
    }
  };

  const handleFileChange = async (event) => {
    try {
      //   console.log("event", event);

      setImageUrl(URL.createObjectURL(event.target.files[0]));
      let imageBase64 = "";
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        console.log("called: ", reader);
        imageBase64 = reader.result;
      };
      await delay(300);
      if (imageBase64 !== "") {
        const res = await updateProfile({ photoBytes: imageBase64 }, "image");
        console.log("res", res);

        // can't success request permission edit profile image
      }
    } catch (err) {
      console.log("err upload image", err);
    }

    // Additional validation logic
  };

  return (
    <div className="app-auth">
      <img className="login-icon-top" src={IsolationTop} alt="IsolationTop" />

      <div>
        <div className="div-center">
          <span className="text-myprofile">My Profile</span>
          <span className="text-login">Please complete your profile.</span>
          <div className="file-upload">
            <img
              className="image-profile"
              src={imageUrl !== null ? imageUrl : EmptyProfile}
              alt="image-profile"
              referrerPolicy="no-referrer"
            />
            {imageUrl !== null && (
              <img
                className="icon-profile"
                src={IconProfile}
                alt="icon-profile"
              />
            )}
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <div style={{ width: 355 }}>
            <TextField
              title="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              isWhite
            />
            <TextField
              title="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              isWhite
            />
          </div>
        </div>
        <Button
          onClick={() => handleUpdateProfile()}
          styles={{ borderWidth: 0 }}
          useZ={true}
        >
          {isLoading ? (
            <div className="div-button-row">
              <ThreeDot
                variant="pulsate"
                color="#59429b"
                size="small"
                text=""
                textColor=""
              />
            </div>
          ) : (
            <div className="div-button-row">
              <span className="text-button">Submit</span>
            </div>
          )}
        </Button>
      </div>

      <img
        className="login-icon-bottom"
        src={IsolationBottom}
        alt="IsolationBottom"
      />
    </div>
  );
};

export default Profile;

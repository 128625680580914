import axios from "axios";

import { ENDPOINTS } from "../assets/constants";

const getConfig = () => {
  const token = localStorage.getItem("access_token");

  const config = {
    headers: {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
    },
  };

  return config;
};

export async function getProfile() {
  try {
    const response = await axios.get(
      `${ENDPOINTS.people}people/me?personFields=names,photos,emailAddresses`,
      getConfig()
    );
    return response.data;
  } catch (error) {
    console.log("err get profile", error);
    return null;
  }
}

export async function updateProfile(data, type = "name") {
  try {
    const response = await axios.patch(
      `${ENDPOINTS.people}${
        type === "name"
          ? "people/me:updateContact?updatePersonFields=names"
          : "people/me/photos:updateContactPhoto"
      }`,
      JSON.stringify(data),
      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err update profile", error);
    return null;
  }
}

export async function getRoomLists() {
  try {
    const response = await axios.get(
      `${ENDPOINTS.googleApis}/calendar/v3/users/me/calendarList`,
      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err get Room list from api", error.status);
    return { status: error.status, data: null };
  }
}

export async function getFreeBusyRoom(data) {
  try {
    const response = await axios.post(
      `${ENDPOINTS.googleApis}/calendar/v3/freeBusy`,
      JSON.stringify(data),
      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err update profile", error);
    return null;
  }
}

export async function getRoomHistory(calendarId, minTime, maxTime) {
  try {
    const response = await axios.get(
      `${ENDPOINTS.googleApis}/calendar/v3/calendars/${calendarId}/events?timeMin=${minTime}&orderBy=startTime&singleEvents=true&timeMax=${maxTime}`,
      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err update profile", error);
    return null;
  }
}

export async function bookingRoom(calendarId, data, getMeetLink = false) {
  try {
    const response = await axios.post(
      `${
        ENDPOINTS.googleApis
      }/calendar/v3/calendars/${calendarId}/events?conferenceDataVersion=${
        getMeetLink ? 1 : 0
      }`,
      data,
      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err update profile", error);
    return null;
  }
}

export async function cancelBookingRoom(calendarId, eventId) {
  try {
    const response = await axios.delete(
      `${ENDPOINTS.googleApis}/calendar/v3/calendars/${calendarId}/events/${eventId}`,

      getConfig()
    );

    return response;
  } catch (error) {
    console.log("err update profile", error);
    return null;
  }
}

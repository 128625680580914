export const ROUTE_PATH = {
  LOGIN: "/",
  PROFILE: "/profile",
  HOME: "/home",
  ROOMS: "/rooms/:roomId",
};

export const meetingRooms = [
  {
    id: "c_188d7dcrt9pu2gfeml7u11dako9to@resource.calendar.google.com",
    name: "Unity Room",
    coverImage: require("../images/rooms/unity_1.jpg"),
    amenities: ["20+ seats", "TV"],
    icons: [require("../images/chair.png"), require("../images/tv.png")],
    isAvailable: true,
  },
  {
    id: "c_188eh43ip155ej8ils152c79f1kv2@resource.calendar.google.com",
    name: "Wisdom Room",
    coverImage: require("../images/rooms/zenita_1.jpg"),
    amenities: ["20+ seats", "TV"],
    icons: [require("../images/chair.png"), require("../images/tv.png")],
    isAvailable: true,
  },
  {
    id: "c_1887l4obljphehthlbc6jbtu4cg1e@resource.calendar.google.com",
    name: "Zenith Room",

    coverImage: require("../images/rooms/wisdom_1.jpg"),
    amenities: ["11 seats", "TV"],
    icons: [require("../images/chair.png"), require("../images/tv.png")],
    isAvailable: true,
  },
  {
    id: "c_188ermik6v948gm3lhf54i5hcclsg@resource.calendar.google.com",
    name: "Spirit Room",
    coverImage: require("../images/rooms/spirit_1.jpg"),
    amenities: ["6 seats", "White board", "TV"],
    icons: [
      require("../images/chair.png"),
      require("../images/board.png"),
      require("../images/tv.png"),
    ],
    isAvailable: true,
  },
  {
    id: "c_188bjk918hqpqi8gme3g67avr2sri@resource.calendar.google.com",
    name: "Echo Room",
    coverImage: require("../images/rooms/spark_1.jpg"),
    amenities: ["4 seats", "White board"],
    icons: [require("../images/chair.png"), require("../images/tv.png")],
    isAvailable: true,
  },

  {
    id: "c_188138aih8jhoip3iuktru03g13km@resource.calendar.google.com",
    name: "Momentum Room",
    coverImage: require("../images/rooms/momentum_1.jpg"),
    amenities: ["4 seats", "White board", "TV"],
    icons: [
      require("../images/chair.png"),
      require("../images/board.png"),
      require("../images/tv.png"),
    ],
    isAvailable: true,
  },
  // {
  //   id: "c_188f72easnf9ii1ugtqua1sqcj36e@resource.calendar.google.com",
  //   name: "Spring Tower-10-Invitrace - Internal Meeting Room (5)",
  //   coverImage:
  //     "https://firebasestorage.googleapis.com/v0/b/uploadfile-cc5a1.appspot.com/o/image.png?alt=media&token=ebe64491-a3a0-4362-b0d1-34be0c0bac8c",
  //   amenities: ["1-5 seats", "White board", "TV", "Snack Bar"],
  //   isAvailable: true,
  // },
];

export const ENDPOINTS = {
  people: "https://people.googleapis.com/v1/",
  googleApis: "https://www.googleapis.com",
};

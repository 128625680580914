import React from "react";
import { ThreeDot } from "react-loading-indicators";

export const LoadingModal = ({ isVisible }) => {
  if (isVisible) {
    return (
      <div className={`modal-loading`}>
        <ThreeDot
          variant="pulsate"
          color="#59429b"
          size="small"
          text=""
          textColor=""
        />
      </div>
    );
  } else {
    return <></>;
  }
};

import React, { createContext, useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setEmployeeProfile,
} from "../utils/redux/reducers/auth";
import { getProfile } from "../utils/apis";

// Create a context for auth state
const AuthContext = createContext();

// Provide the auth context
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return !!localStorage.getItem("access_token");
  });

  useEffect(() => {
    if (isAuthenticated) {
      handleGetProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Simulate login/logout (You'd replace these with real logic)
  const login = (token) => {
    localStorage.setItem("access_token", token);

    // const tokens = localStorage.getItem("access_token");
    // console.log("tokens", tokens);

    dispatch(setAccessToken(token));
    handleGetProfile();
    setIsAuthenticated(true);
  };
  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginGoogleSuccess(tokenResponse),
    onError: (error) => console.log("Login Failed:", error),
    scope: "https://www.googleapis.com/auth/calendar.events",
  });

  const handleLoginGoogleSuccess = async (tokenResponse) => {
    try {
      console.log(tokenResponse);
      localStorage.setItem("access_token", tokenResponse.access_token);
      dispatch(setAccessToken(tokenResponse.access_token));
      window.location.reload();
    } catch (error) {
      console.log("err login google", error);
    }
  };
  const handleGetProfile = async () => {
    const res = await getProfile();

    dispatch(setEmployeeProfile(res));
  };

  const refreshToken = () => {
    handleLoginGoogle();
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    // Cookies.remove("access_token");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, refreshToken, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

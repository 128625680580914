import React from "react";

export const TextField = (props) => {
  return (
    <div className="div-textfield">
      <span
        className="div-text-input"
        style={{ color: props.isWhite ? "#ffffff" : "black" }}
      >
        {props.title}
      </span>
      <input
        className="text-input"
        type="text"
        style={{
          border: `1px solid ${props.isWhite ? "#ffffff" : "black"}`,
          color: props.isWhite ? "#ffffff" : "black",
        }}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/authContext";
import { useSelector } from "react-redux";
import { selectEmployeeProfile } from "../../utils/redux/reducers/auth";
import {
  getRoomLists,
  getRoomHistory,
  cancelBookingRoom,
} from "../../utils/apis";
import {
  Header,
  Tabs,
  Card,
  HistoryCard,
  LoadingModal,
} from "../../components";
import { meetingRooms } from "../../assets/constants";
import { delay } from "../../utils";

const Home = () => {
  //   const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { refreshToken } = useAuth();
  const employeeProfile = useSelector(selectEmployeeProfile);
  const [roomLists, setRoomLists] = useState([]);
  const [historyLists, setHistoryLists] = useState([]);
  const [tabKey, setTabKey] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handlesetTabKey(tabKey);
    const key = searchParams.get("tab_key");
    if (key !== null) {
      handlesetTabKey(parseInt(key));
    }

    // console.log("searchParams", searchParams);

    // console.log("key", key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetRoomLists = async () => {
    try {
      setIsLoading(true);
      const res = await getRoomLists();

      if (res.status === 200) {
        console.log("res room", res);

        // const items = res.data.items;
        // const filteredMeetingRooms = meetingRooms.filter((room) =>
        //   items.some((item) => item.id === room.id)
        // );

        setRoomLists(meetingRooms);
        setIsLoading(false);
      } else if (res.status === 401) {
        refreshToken();
        setIsLoading(false);
      }
    } catch (error) {
      console.log("err get room lists", error);
      setIsLoading(false);
    }
  };

  const handleGetHistory = async () => {
    try {
      setIsLoading(true);
      const { startOfDay, endOfDay } = getStartAndEndTimeOfToday();

      await delay(1000);

      const resposne = await getRoomHistory(
        employeeProfile?.emailAddresses[0]?.value,
        startOfDay.toISOString(),
        endOfDay.toISOString()
      );
      console.log(resposne);

      if (resposne.status === 200) {
        const items = resposne.data.items;
        const filteredResults = filterAndMergeEventsWithRooms(
          items,
          meetingRooms
        );
        setHistoryLists(filteredResults);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const filterAndMergeEventsWithRooms = (events, meetingRooms) => {
    return events
      .filter((event) => {
        // Check if any attendee's email matches a meeting room ID
        return (
          event.attendees &&
          event.attendees.some((attendee) =>
            meetingRooms.some((room) => room.id === attendee.email)
          )
        );
      })
      .map((event) => {
        // Find the matching room based on attendee email
        const matchedRoom = meetingRooms.find((room) =>
          event.attendees.some((attendee) => attendee.email === room.id)
        );

        // Merge event data with the matching room details
        return {
          ...event,
          meetingRoomDetails: matchedRoom ? matchedRoom : null,
        };
      });
  };

  const getStartAndEndTimeOfToday = () => {
    // Get the current date
    const now = new Date();

    // Create a new Date object for the start of today
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0
    );

    // Create a new Date object for the end of today
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth() + 1, // Next month
      0, // Day 0 of next month is the last day of current month
      23,
      59,
      59
    );

    return {
      startOfDay,
      endOfDay,
    };
  };

  const handlesetTabKey = (key) => {
    setTabKey(key);
    switch (key) {
      case 1:
        handleGetRoomLists();
        break;
      case 2:
        handleGetHistory();
        break;

      default:
        handleGetRoomLists();
    }
  };

  const handleCancelEvent = async (item) => {
    const response = await cancelBookingRoom(item.creator.email, item.id);

    if (response.status === 204) {
      handleGetHistory();
    }
  };

  return (
    <div>
      <Header></Header>
      <Tabs
        tabKey={tabKey}
        onClick={() => handlesetTabKey(1)}
        onClick2={() => handlesetTabKey(2)}
      ></Tabs>
      <div style={{ marginTop: 20 }}>
        {tabKey === 1
          ? roomLists.map((item, idx) => (
              <div key={idx}>
                <Card item={item}></Card>
              </div>
            ))
          : historyLists.map((item, idx) => (
              <div key={idx}>
                <HistoryCard
                  item={item}
                  onClick={() => handleCancelEvent(item)}
                  disabled={isLoading}
                ></HistoryCard>
              </div>
            ))}
      </div>
      <LoadingModal isVisible={isLoading} />
    </div>
  );
};

export default Home;

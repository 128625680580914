import React from "react";
import { useSelector } from "react-redux";
import { selectEmployeeProfile } from "../../utils/redux/reducers/auth";
import Logo from "../../assets/images/logo.png";
import EmtpyProfile from "../../assets/images/emtpy-profile.png";

export const Header = () => {
  const employeeProfile = useSelector(selectEmployeeProfile);
  return (
    <div className="div-header">
      <div />
      <img className="image-logo" src={Logo} alt="header-logo" />
      <img
        className="image-logo"
        src={
          Object.keys(employeeProfile).length !== 0
            ? employeeProfile?.photos[0]?.url
            : EmtpyProfile
        }
        alt="header-logo"
        referrerPolicy="no-referrer"
      />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSetSelectRoomTime } from "../../utils/redux/reducers/rooms";

const generateTimeSlots = (start, end, interval) => {
  const timeSlots = [];
  let currentHour = start;
  while (currentHour < end) {
    const hours = Math.floor(currentHour);
    const minutes = (currentHour % 1) * 60;
    const timeString = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    timeSlots.push(timeString);
    currentHour += interval;
  }

  return timeSlots;
};

export const TimeSelection = (props) => {
  const dispatch = useDispatch();
  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [isDragging, setIsDragging] = useState(false);

  const hours = generateTimeSlots(9, 24, 0.5) || [];

  const busyHours = props.busy.map((b) => ({
    start: moment(b.start.dateTime).format("HH:mm"),
    end: moment(b.end.dateTime).format("HH:mm"),
    email: b.creator.email,
  }));

  const isBusy = (hour) => {
    return busyHours.find((b) => {
      const startIndex = hours.indexOf(b.start);
      const endIndex = hours.indexOf(b.end);
      const currentIndex = hours.indexOf(hour);
      return currentIndex >= startIndex && currentIndex < endIndex;
    });
  };

  const updateSelectedRange = (newStart, newEnd) => {
    setSelectedRange({ start: newStart, end: newEnd });
    dispatch(setSetSelectRoomTime({ start: newStart, end: newEnd }));
  };

  const handleMouseDown = (hour) => {
    if (!isBusy(hour)) {
      updateSelectedRange(hour, hour);
      setIsDragging(true);
    }
  };

  const handleMouseEnter = (hour) => {
    if (isDragging && !isBusy(hour)) {
      updateSelectedRange(selectedRange.start, hour);
      // setSelectedRange((prev) => ({ ...prev, end: hour }));
    }
  };

  const handleMouseUp = () => setIsDragging(false);

  const handleTouchStart = (hour) => {
    if (!isBusy(hour)) {
      updateSelectedRange(hour, hour);
      // setSelectedRange({ start: hour, end: hour });
      // dispatch(setSetSelectRoomTime({ start: hour, end: hour }));
      setIsDragging(true);
    }
  };

  const handleTouchMove = (event) => {
    event.preventDefault(); // Prevents scrolling while dragging

    const touchedElement = document.elementFromPoint(
      event.touches[0].clientX,
      event.touches[0].clientY
    );
    if (
      touchedElement &&
      touchedElement.dataset &&
      touchedElement.dataset.hour &&
      isDragging
    ) {
      const hour = touchedElement.dataset.hour;
      if (!isBusy(hour)) {
        setSelectedRange((prev) => ({ ...prev, end: hour }));
        dispatch(
          setSetSelectRoomTime({ start: selectedRange.start, end: hour })
        );
      }
    }
  };

  const handleTouchEnd = () => setIsDragging(false);

  // Attach custom touch event listeners on mount
  useEffect(() => {
    document.addEventListener("touchmove", handleTouchMove, {
      passive: isDragging ? false : true,
    });
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const isSelected = (hour) => {
    if (!selectedRange.start || !selectedRange.end) return false;
    const startIndex = hours.indexOf(selectedRange.start);
    const endIndex = hours.indexOf(selectedRange.end);
    const currentIndex = hours.indexOf(hour);
    return (
      currentIndex >= Math.min(startIndex, endIndex) &&
      currentIndex <= Math.max(startIndex, endIndex)
    );
  };

  return (
    <div
      style={{
        maxWidth: "100%",
        padding: "10px",
        fontFamily: "Arial, sans-serif",
        overflowY: "auto", // Enable vertical scrolling
        maxHeight: "80vh",
      }}
    >
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div style={{ width: "60px", marginRight: "10px" }}>
          {hours.map((hour) => (
            <div
              key={hour}
              style={{
                height: "41px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "10px",
                color: "#666",
              }}
            >
              {hour}
            </div>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          {hours.map((hour, idx) => {
            const busyInfo = isBusy(hour);

            return (
              <div
                key={idx}
                data-hour={hour}
                onMouseDown={() => !busyInfo && handleMouseDown(hour)}
                onMouseEnter={() => !busyInfo && handleMouseEnter(hour)}
                onMouseUp={() => !busyInfo && handleMouseUp()}
                onTouchStart={() => !busyInfo && handleTouchStart(hour)}
                style={{
                  height: "40px",
                  backgroundColor: busyInfo
                    ? "#f0f0f0"
                    : isSelected(hour)
                    ? "#5d3fd3"
                    : "#fff",
                  cursor: busyInfo ? "not-allowed" : "pointer",
                  borderLeft: "1px solid #ddd",
                  borderTop: `${idx === 0 ? 1 : 0}px solid #ddd`,
                  borderBottom: `${isSelected(hour) ? 0 : 1}px solid #ddd`,
                  borderRadius: isSelected(hour) ? "10px" : "0",
                  transition: "all 0.2s ease",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {busyInfo && (
                  <span
                    style={{ marginLeft: 10, fontSize: "10px", color: "#888" }}
                  >
                    {busyInfo.email.split("@")[0]}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

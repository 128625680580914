import React from "react";
import { ReactComponent as IconDown } from "../../assets/svg/chevron-down.svg";

export const Modal = ({ children, showModal, handleClose }) => {
  return (
    <div className={`modal ${showModal ? "show" : ""}`}>
      <div className="modal-content">
        <span className="close-btn" onClick={handleClose}>
          <IconDown></IconDown>
        </span>
        <div>{children}</div>
      </div>
    </div>
  );
};

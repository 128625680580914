import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "../src/contexts/authContext";
// import { ChakraProvider } from "@chakra-ui/react";

import { Provider } from "react-redux";
import store from "./utils/redux/store";

import Routes from "./routes";

import "./styles/pageStyles.css";
function App() {
  return (
    <div>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="1086728254388-depcflmu0lpd0u3n8v22u4l18ial9eco.apps.googleusercontent.com">
          <AuthProvider>
            <Routes></Routes>
          </AuthProvider>
        </GoogleOAuthProvider>
      </Provider>
    </div>
  );
}

export default App;

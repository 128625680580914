import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { ROUTE_PATH } from "../assets/constants";

import Login from "../pages/login";
import Profile from "../pages/profile";
import Home from "../pages/home";
import Rooms from "../pages/rooms";
import { useAuth } from "../contexts/authContext";

function RouteDoms() {
  const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? children : <Navigate to={ROUTE_PATH.LOGIN} />;
  };

  return (
    <Router>
      <Routes>
        <Route exact path={ROUTE_PATH.LOGIN} element={<Login />} />
        <Route
          path={ROUTE_PATH.PROFILE}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_PATH.HOME}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_PATH.ROOMS}
          element={
            <ProtectedRoute>
              <Rooms />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default RouteDoms;

import React from "react";
import { ReactComponent as RoomIcon } from "../../assets/svg/room-icon.svg";
import { ReactComponent as BookingIcon } from "../../assets/svg/booking-icon.svg";

export const Tabs = (props) => {
  return (
    <div className="div-tabs">
      <button
        className="btn-tab"
        style={{
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 2,
          borderBottomColor: props.tabKey === 1 ? "#59429B" : "#E8E8E8",
        }}
        onClick={props.onClick}
      >
        <RoomIcon
          style={{ fill: props.tabKey === 1 ? "#59429B" : "#6C6C6C" }}
        ></RoomIcon>
        <span
          className="btn-text-tab"
          style={{ color: props.tabKey === 1 ? "#59429B" : "#6C6C6C" }}
        >
          Rooms
        </span>
      </button>
      <button
        className="btn-tab"
        style={{
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 2,
          borderBottomColor: props.tabKey === 2 ? "#59429B" : "#E8E8E8",
        }}
        onClick={props.onClick2}
      >
        <BookingIcon
          style={{ fill: props.tabKey === 2 ? "#59429B" : "#6C6C6C" }}
        ></BookingIcon>
        <span
          className="btn-text-tab"
          style={{ color: props.tabKey === 2 ? "#59429B" : "#6C6C6C" }}
        >
          Your Booking
        </span>
      </button>
    </div>
  );
};

import React from "react";
import moment from "moment";
import { Button } from "../button";

export const HistoryCard = (props) => {
  const { item } = props;

  const filteredAmenutues = item.meetingRoomDetails.amenities.slice(0, 2);
  console.log("item", item);

  return (
    <div className="div-card">
      <img
        className="image-card"
        src={item.meetingRoomDetails.coverImage}
        alt="header-logo"
      />
      <span className="text-card-summary">{item.summary}</span>
      <div className="div-card-description">
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="text-card-title">
              {item.meetingRoomDetails.name}
            </span>
            <div
              style={{
                textAlign: "left",
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {filteredAmenutues?.map((item, idx) => (
                <div key={idx}>
                  <span>{item}</span>

                  <span
                    className="divider"
                    style={{
                      backgroundColor:
                        filteredAmenutues.length - 1 !== idx
                          ? "black"
                          : "white",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 1,
            width: "40%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <span
              style={{
                fontSize: 16,
                color: "black",
                textAlign: "right",
              }}
            >
              {`${moment(item.start.dateTime).format("dddd,MMM DD")}`}
            </span>
            <span
              style={{
                fontSize: 16,
                color: "black",
                textAlign: "right",
              }}
            >
              {`${moment(item.start.dateTime).format("HH:mm")}-${moment(
                item.end.dateTime
              ).format("HH:mm")}`}
            </span>
          </div>
        </div>
      </div>
      {item.organizer.self !== undefined && item.organizer.self === true && (
        <Button
          styles={{
            marginTop: 10,
            border: `1px solid ${props.disabled ? "#909090" : "red"}`,
          }}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          <span
            style={{
              fontSize: 16,
              color: `${props.disabled ? "#909090" : "red"}`,
            }}
          >
            Cancel Booking
          </span>
        </Button>
      )}
    </div>
  );
};

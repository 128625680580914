import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectEmployeeProfile } from "../../utils/redux/reducers/auth";
import { selectRoom, selectRoomTime } from "../../utils/redux/reducers/rooms";
import { parse, format } from "date-fns";
import { bookingRoom, getRoomHistory } from "../../utils/apis";
import {
  TimeSelection,
  Button,
  Modal,
  TextField,
} from "../../components/index";
import { ThreeDot } from "react-loading-indicators";
import { ROUTE_PATH } from "../../assets/constants";

const Rooms = () => {
  //   const { roomId } = useParams();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [busyTime, setBusyTime] = useState([]);

  const [selectDay, setSelectDay] = useState({
    day: new Date().getDate(),
    key: 0,
  });
  const [remainingDays, setRemainingDays] = useState([]);

  const month = new Date().toLocaleString("default", { month: "short" });
  const employeeProfile = useSelector(selectEmployeeProfile);
  const roomTime = useSelector(selectRoomTime);
  const roomDetail = useSelector(selectRoom);
  const filteredAmenutues = roomDetail.amenities.slice(0, 2);

  useEffect(() => {
    // daysUntilEndOfMonth();
    getDatesForNextTwoMonths();
    handleCheckFreeOrBusy(selectDay.day);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDatesForNextTwoMonths = () => {
    const dates = [];
    const today = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    for (let i = 0; i < 365; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);

      const day = date.getDate();
      const month = monthNames[date.getMonth()]; // Get month name from array

      dates.push({ day, month });
    }
    setRemainingDays(dates);
    // return dates;
  };

  const handleCheckFreeOrBusy = async (day) => {
    try {
      const { startDate, endDate } = getTodayDateRange(day);

      const response = await getRoomHistory(roomDetail.id, startDate, endDate);
      // console.log("response", response);
      if (response.status === 200) {
        // console.log("sss", response.data.calendars[`${roomDetail.id}`].busy);
        setBusyTime(response.data.items);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const getTodayDateRange = (day) => {
    // Create a new Date object for the current time
    const today = new Date();

    // Format start date as "YYYY-MM-DDT00:00:00-07:00" (Midnight of today)
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      day,
      0,
      0,
      0
    ).toISOString();

    // Format end date as "YYYY-MM-DDT23:59:00-07:00" (End of today)
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      day,
      23,
      59,
      0
    ).toISOString();

    return { startDate, endDate };
  };

  const handleBookingRoom = async () => {
    setIsLoading(true);
    const startDateISO = convertToISOFormat(
      `${selectDay.day} ${month} ${new Date().getFullYear()} ${roomTime.start}`
    );

    const endDateISO = convertToISOFormat(
      `${
        selectDay.day
      } ${month} ${new Date().getFullYear()} ${addThirtyMinutesIfSame(
        roomTime.start,
        roomTime.end
      )}`
    );

    const data = {
      summary: title,
      description: "",
      start: {
        dateTime: startDateISO,
        timeZone: "Asia/Bangkok",
      },
      end: {
        dateTime: endDateISO,
        timeZone: "Asia/Bangkok",
      },
      attendees: [
        {
          email: employeeProfile.emailAddresses[0].value,
          displayName: employeeProfile.names[0].displayName,
        },

        {
          email: roomDetail.id,
          displayName: roomDetail.name,
          resource: true,
          responseStatus: "accepted",
        },
      ],
      conferenceData: {
        createRequest: {
          requestId: generateRandomString(),
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
        },
      },
    };

    const response = await bookingRoom(
      employeeProfile.emailAddresses[0].value,
      data,
      true
    );
    console.log("response booking", response);

    if (response.status === 200) {
      setIsLoading(false);
      navigate(`${ROUTE_PATH.HOME}?tab_key=2`);
    } else {
      setIsLoading(false);
    }
  };

  const addThirtyMinutesIfSame = (start, end) => {
    if (start === end) {
      // Convert start time to Date object to manipulate time easily
      const startDate = new Date(`1970-01-01T${start}:00`);
      const newEndDate = new Date(startDate.getTime() + 30 * 60000); // Add 30 minutes
      const newEndTime = newEndDate.toTimeString().slice(0, 5); // Format to "HH:MM"
      return newEndTime;
    } else {
      return end;
    }
  };

  const convertToISOFormat = (dateString) => {
    // Parse the date using date-fns
    const fixedDateString = dateString.replace(/(\d{1,2})\.(\d{2})/, "$1:$2"); // Converts '9.00' to '09:00'
    const parsedDate = parse(fixedDateString, "dd MMM yyyy HH:mm", new Date());
    // Format the date in ISO format
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");

    return formattedDate;
  };
  const generateRandomString = () => {
    // Function to generate a random alphanumeric character (0-9, a-z)
    const getRandomChar = () => {
      const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
      return chars[Math.floor(Math.random() * chars.length)];
    };

    // Generate the random string in the format "xxxx-xxxx-xxxx"
    const randomString =
      `${getRandomChar()}${getRandomChar()}${getRandomChar()}${getRandomChar()}-` +
      `${getRandomChar()}${getRandomChar()}${getRandomChar()}${getRandomChar()}-` +
      `${getRandomChar()}${getRandomChar()}${getRandomChar()}${getRandomChar()}`;

    return randomString;
  };

  return (
    <div className="main-container">
      <div className="content-padding">
        <div
          className="image-cover"
          style={{
            backgroundImage: `url(${roomDetail.coverImage})`,
            width: "100%",
            height: "189px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          src={roomDetail.coverImage}
          alt="cover"
        >
          <button
            onClick={() => navigate(ROUTE_PATH.HOME)}
            style={{
              backgroundColor: "rgb(255,255,255,0.5)",
              border: "none",

              padding: 10,
              borderRadius: 99,
              marginLeft: 12,
              marginTop: 12,
            }}
          >
            <img
              src={require("../../assets/images/left-arrow.png")}
              alt="icon-back"
              style={{
                width: "14px",
                height: "15px",
              }}
            />
          </button>
        </div>
        <div className="div-room-padding-column">
          <span className="text-room">{roomDetail.name.toUpperCase()}</span>
          <span className="text-room-amenities">Amenities</span>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {roomDetail?.amenities.map((text, idx) => (
              <div
                key={idx}
                style={{ flex: "flex", flexDirection: "row", width: "50%" }}
              >
                <img
                  src={roomDetail.icons[idx]}
                  alt="icon-amenities"
                  style={{
                    width: "13.33px",
                    height: "10.67px",
                    marginRight: 10,
                  }}
                />
                <span className="text-room-amenities">{text}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="div-divider-room" />
        <div className="div-room-padding-column">
          <div className="div-day">
            {remainingDays.map((date, idx) => (
              <button
                style={{
                  backgroundColor: selectDay.key === idx ? "#6719CF" : "white",
                }}
                className="btn-day"
                key={idx}
                onClick={() => {
                  setSelectDay({ day: date.day, key: idx });
                  handleCheckFreeOrBusy(date.day);
                }}
              >
                <span
                  style={{
                    color: selectDay.key === idx ? "white" : "black",
                  }}
                  className="btn-text-month"
                >
                  {date.month}
                </span>
                <span
                  style={{
                    color: selectDay.key === idx ? "white" : "black",
                  }}
                  className="btn-text-day"
                >
                  {date.day}
                </span>
              </button>
            ))}
          </div>
          <div style={{ marginBottom: 30 }}>
            <TimeSelection busy={busyTime} />
          </div>
        </div>
      </div>
      <footer
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          textAlign: "center",
          padding: "20px 0",
          boxShadow: "0px 0px 10px 0px #00000040",
        }}
      >
        <Button
          styles={{
            borderWidth: 0,
            backgroundColor: "#5d3fd3",
            color: "white",
          }}
          onClick={() => setOpen(true)}
        >
          <span className="btn-text-day">Submit</span>
        </Button>
      </footer>
      <Modal showModal={isOpen} handleClose={() => setOpen(false)}>
        <div className="div-card">
          <span className="modal-text-review">Review booking</span>
          <span className="modal-text-date-time">{`${
            selectDay.day
          },${month} ${new Date().getFullYear()} | ${
            roomTime.start
          } - ${addThirtyMinutesIfSame(roomTime.start, roomTime.end)}`}</span>
          <img
            className="image-cover-modal"
            src={roomDetail.coverImage}
            alt="cover-modal"
          />
          <span className="text-room">{roomDetail.name.toUpperCase()}</span>
          <div
            style={{
              textAlign: "left",
              marginTop: 5,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {filteredAmenutues?.map((item, idx) => (
              <div key={idx}>
                <span style={{ color: "#BBBBBB", fontSize: 12 }}>{item}</span>

                <span
                  className="divider"
                  style={{
                    backgroundColor:
                      filteredAmenutues.length - 1 !== idx
                        ? "#BBBBBB"
                        : "white",
                  }}
                />
              </div>
            ))}
          </div>
          <div style={{ marginBottom: 10 }}>
            <TextField
              title="Title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <Button
            styles={{
              marginTop: 10,
              borderWidth: 0,
              backgroundColor: "#5d3fd3",
              color: "white",
            }}
            onClick={() => handleBookingRoom()}
            disabled={isLoading}
          >
            {isLoading ? (
              <ThreeDot
                variant="pulsate"
                color="white"
                size="small"
                text=""
                textColor=""
              />
            ) : (
              <span className="btn-text-day">Submit</span>
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Rooms;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRoomData } from "../../utils/redux/reducers/rooms";
import { ROUTE_PATH } from "../../assets/constants";
import { getFreeBusyRoom } from "../../utils/apis";
import { ReactComponent as AvailableIcon } from "../../assets/svg/Glyph.svg";
import { ReactComponent as UnAvailableIcon } from "../../assets/svg/cancel-event.svg";
import moment from "moment";

// Convert busy times to a more usable format (helper function)
const getHourFromISOString = (isoString) => {
  const date = new Date(isoString);
  return date.getUTCHours() + date.getUTCMinutes() / 60; // Convert to decimal hours
};
const convertDecimalToTime = (decimalHours) => {
  const hours = Math.floor(decimalHours); // Get the integer part for hours
  const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal part to minutes

  // Format hours and minutes to two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

const checkIfFreeAndNextFreeTime = (currentHour, busyPeriods) => {
  // Convert currentHour to a decimal value for easier comparison
  const currentHourDecimal = parseFloat(currentHour);

  // Iterate through busy periods to check if the time falls within any busy period
  for (let i = 0; i < busyPeriods.length; i++) {
    const busyStart = getHourFromISOString(busyPeriods[i].start);

    const busyEnd = getHourFromISOString(busyPeriods[i].end);

    if (currentHourDecimal >= busyStart && currentHourDecimal < busyEnd) {
      // If the current time is busy, return when it will be free
      return {
        isFree: false,
        nextFreeTime: convertDecimalToTime(busyEnd), // Free time starts at the end of this busy period
      };
    } else {
      return {
        isFree: true,
        nextFreeTime: convertDecimalToTime(busyStart), // Free time starts at the end of this busy period
      };
    }
  }

  // If the time is free, return true
  return { isFree: true, nextFreeTime: null };
};

export const Card = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ava, setAva] = useState({ isFree: true, nextFreeTime: "" });
  const { item } = props;

  useEffect(() => {
    handleCheckFreeOrBusy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckFreeOrBusy = async () => {
    try {
      const { startDate, endDate } = getTodayDateRange();

      const response = await getFreeBusyRoom({
        timeMin: startDate,
        timeMax: endDate,
        items: [
          {
            id: item.id,
          },
        ],
      });

      if (response.status === 200) {
        const { isFree, nextFreeTime } = checkIfFreeAndNextFreeTime(
          moment().format("HH:mm"),
          response.data.calendars[`${item.id}`].busy
        );

        setAva({ isFree: isFree, nextFreeTime: nextFreeTime });
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const getTodayDateRange = () => {
    // Create a new Date object for the current time
    const today = new Date();

    // Format start date as "YYYY-MM-DDT00:00:00-07:00" (Midnight of today)
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    )
      .toISOString()
      .replace("Z", "-07:00"); // Adjust timezone offset as required

    // Format end date as "YYYY-MM-DDT23:59:00-07:00" (End of today)
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      0
    )
      .toISOString()
      .replace("Z", "-07:00"); // Adjust timezone offset as required

    return { startDate, endDate };
  };
  const filteredAmenutues = item.amenities.slice(0, 2);

  const handleOnClick = () => {
    dispatch(setRoomData(item));
    navigate(ROUTE_PATH.ROOMS.replace(":roomId", item.id));
  };

  return (
    <div className="div-card" onClick={() => handleOnClick()}>
      <img className="image-card" src={item.coverImage} alt="header-logo" />
      <div className="div-card-description">
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="text-card-title">{item.name.toUpperCase()}</span>
            <div
              style={{
                textAlign: "left",
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {filteredAmenutues?.map((item, idx) => (
                <div key={idx}>
                  <span>{item}</span>

                  <span
                    className="divider"
                    style={{
                      backgroundColor:
                        filteredAmenutues.length - 1 !== idx
                          ? "black"
                          : "white",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: 14,
            }}
          >
            {ava.isFree ? <AvailableIcon /> : <UnAvailableIcon />}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
            }}
          >
            <span style={{ fontSize: 11, marginBottom: 5, textAlign: "right" }}>
              {ava.isFree ? "Available" : "Occupied"}
            </span>
            <span
              style={{
                fontSize: 11,
                color: ava.isFree ? "#34C759" : "#4E4E4E",
                textAlign: "right",
              }}
            >
              {`Until ${ava.nextFreeTime !== null ? ava.nextFreeTime : "-"}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

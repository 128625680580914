import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "../../components/button";
import { ROUTE_PATH } from "../../assets/constants";
import { useAuth } from "../../contexts/authContext";
import { ThreeDot } from "react-loading-indicators";
import { delay } from "../../utils";
import IsolationTop from "../../assets/images/Isolation_mode_top.png";
import IconError from "../../assets/images/icon_error.png";
import GoogleLogo from "../../assets/images/google.png";
import IsolationBottom from "../../assets/images/Isolation_mode_bottom.png";

const Login = () => {
  const { login } = useAuth();

  const [isError, SetIsError] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginGoogleSuccess(tokenResponse),
    onError: (error) => {
      console.log("Login Failed:", error);
      SetIsLoading(true);
    },
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  });

  const handleLoginGoogleSuccess = async (tokenResponse) => {
    try {
      SetIsLoading(true);

      login(tokenResponse.access_token);
      await delay(3000);
      SetIsLoading(false);
      const isFirstTime = localStorage.getItem("is_first_time");

      if (isFirstTime === null || isFirstTime === false) {
        navigate(ROUTE_PATH.PROFILE);
      } else {
        navigate(ROUTE_PATH.HOME);
      }
    } catch (error) {
      console.log("err login google", error);
    }
  };

  return (
    <div className="app-auth">
      {isError === false && (
        <img className="login-icon-top" src={IsolationTop} alt="IsolationTop" />
      )}
      <div>
        <div className="div-center">
          {isError ? (
            <div className="div-err-column">
              <img src={IconError} alt="error" />
              <span className="text-err-login">Something went wrong</span>
              <span className="text-login">
                Please try again with company email.
                <br /> If the problem persists, please contact
                <br /> technical support.
              </span>
            </div>
          ) : (
            <span className="text-login">
              A.I. Digital Biomarkers <br /> Wellbeing-Driven Innovation
            </span>
          )}
        </div>
        <Button
          onClick={() => (isError ? SetIsError(false) : handleLoginGoogle())}
          styles={{ borderWidth: 0 }}
          disabled={isLoading}
          useZ={true}
        >
          {isError ? (
            <div className="div-button-row">
              <span className="text-button">Back</span>
            </div>
          ) : isLoading ? (
            <div className="div-button-row">
              <ThreeDot
                variant="pulsate"
                color="#59429b"
                size="small"
                text=""
                textColor=""
              />
            </div>
          ) : (
            <div className="div-button-row">
              <img src={GoogleLogo} alt="googleLogo" />
              <span className="text-button">Sign in with Google Account</span>
            </div>
          )}
        </Button>
      </div>
      {isError === false && (
        <img
          className="login-icon-bottom"
          src={IsolationBottom}
          alt="IsolationBottom"
        />
      )}
    </div>
  );
};

export default Login;

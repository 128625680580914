import { createSlice } from "@reduxjs/toolkit";

export const rooms = createSlice({
  name: "roomsStore",
  initialState: {
    room: null,
    selectedRoomTime: {
      start: null,
      end: null,
    },
  },
  reducers: {
    setRoomData: (state, action) => {
      state.room = action.payload;
    },
    setSetSelectRoomTime: (state, action) => {
      state.selectedRoomTime = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRoomData, setSetSelectRoomTime } = rooms.actions;

export const selectRoom = (state) => state.rooms.room;
export const selectRoomTime = (state) => state.rooms.selectedRoomTime;

export default rooms.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "authStore",
  initialState: {
    accessToken: null,
    employeeProfile: {
      resourceName: "",
      etag: "",
      names: [
        {
          metadata: {
            primary: null,
            source: {
              type: "",
              id: "",
            },
            sourcePrimary: null,
          },
          displayName: "",
          familyName: "",
          givenName: "",
          displayNameLastFirst: "",
          unstructuredName: "",
        },
      ],
      photos: [
        {
          metadata: {
            primary: null,
            source: {
              type: "",
              id: "",
            },
          },
          url: "",
          default: null,
        },
      ],
      emailAddresses: [
        {
          metadata: {
            primary: null,
            verified: null,
            source: {
              type: "",
              id: "",
            },
            sourcePrimary: true,
          },
          value: "",
        },
      ],
    },
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setEmployeeProfile: (state, action) => {
      state.employeeProfile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccessToken, setEmployeeProfile } = auth.actions;

export const selectAccessToken = (state) => state.auth.accessToken;
export const selectEmployeeProfile = (state) => state.auth.employeeProfile;

export default auth.reducer;
